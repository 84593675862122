import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-regular-svg-icons';
import { 
    faPlus, 
    faXmark, 
    faTrashCan, 
    faPaperclip, 
    faBackwardStep, 
    faPenToSquare, 
    faMagnifyingGlass, 
    faRightToBracket, 
    faCheck, 
    faHouse, 
    faCreditCard, 
    faFileInvoiceDollar, 
    faPeopleArrows, 
    faClock, 
    faCalendarDays, 
    faMoneyBillTrendUp, 
    faSunPlantWilt, 
    faPieChart, 
    faRightFromBracket, 
    faScrewdriverWrench, 
    faArrowTrendUp, 
    faArrowTrendDown,
    faAnglesDown,
    faAnglesUp,
    faEye,
} from '@fortawesome/free-solid-svg-icons';


const Server = {
    ApiURL: 'https://api.accufinance.com',
    //ApiURL: 'https://accufinance-api.azurewebsites.net',
    //ApiURL: 'https://localhost:44370',
  };


  export const AccountTypes  = {
      BANKACCOUNT: 1,
      THIRDPARTY: 2
  }


  export const TransactionTypes = {
      PAYMENT: 1,
      DEPOSIT: 2,
      TRANSFER: 3
  }

  export const DataTypes = {
      TEXT: 1,
      NUMBER: 2,
  }

  export const BudgetTypes = {
      WEEKLY: 1,
      MONTHLY: 2
  }

  export const WeekendOnlyTypes = {
      NO: 1,
      FRIDAY: 2,
      MONDAY: 3
  }

  export const ButtonIcons = {
      SAVE: <FontAwesomeIcon icon={faFloppyDisk} />, // "fas fa-save",
      OK: <FontAwesomeIcon icon={faCheck} />,
      CANCEL: <FontAwesomeIcon icon={faXmark} />,
      DELETE: <FontAwesomeIcon icon={faTrashCan} />,
      EDIT: <FontAwesomeIcon icon={faPenToSquare} />,
      BACK: <FontAwesomeIcon icon={faBackwardStep} />,
      ADD: <FontAwesomeIcon icon={faPlus} />,
      LOGIN: <FontAwesomeIcon icon={faRightToBracket} />,
      CREATE: <FontAwesomeIcon icon={faPlus} />,
      ATTACH: <FontAwesomeIcon icon={faPaperclip} />,
      LINK: "fas fa-link",
      SEARCH: <FontAwesomeIcon icon={faMagnifyingGlass} />,
      HOME: <FontAwesomeIcon icon={faHouse} />,
      TRANSACTION: <FontAwesomeIcon icon={faCreditCard} />,
      ACCOUNTS: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
      THIRDPARTIES: <FontAwesomeIcon icon={faPeopleArrows} />,
      SCHEDULES: <FontAwesomeIcon icon={faClock} />,
      CALENDAR: <FontAwesomeIcon icon={faCalendarDays} />,
      INVESTMENTS: <FontAwesomeIcon icon={faMoneyBillTrendUp} />,
      BUDGETS: <FontAwesomeIcon icon={faSunPlantWilt} />,
      REPORTING: <FontAwesomeIcon icon={faPieChart} />,
      LOGOUT: <FontAwesomeIcon icon={faRightFromBracket} />,
      SETTINGS: <FontAwesomeIcon icon={faScrewdriverWrench} />,
      TRENDUP: <FontAwesomeIcon icon={faArrowTrendUp} />,
      TRENDDOWN: <FontAwesomeIcon icon={faArrowTrendDown} />,
      EXPAND: <FontAwesomeIcon icon={faAnglesDown} />,
      COLLAPS: <FontAwesomeIcon icon={faAnglesUp} />,
      VIEW: <FontAwesomeIcon icon={faEye} />,
      YES: <FontAwesomeIcon icon={faCheck} />,
      NO: <FontAwesomeIcon icon={faXmark} />,

  }

  export const ChartColours = [
    "#00876C", "#599D71", "#8DB37C", "#BDC88E", "#EBDDA8", "#E6BB7F", "#E39661", "#DE6D52", "#D43D51",
    "#B84D4D", "#A23E3F", "#8B2D2D", "#731D1D", "#5A0E0E", "#440000", "#2E0000", "#FFD700", "#FF8C00",
    "#FF4500", "#FF1493", "#FF69B4", "#DB7093", "#C71585", "#8B0000", "#A52A2A", "#B22222", "#DC143C",
    "#FF6347", "#FF7F50", "#FF4500", "#FFA07A", "#FA8072", "#E9967A", "#FF8C00", "#FFD700", "#DAA520",
    "#EEE8AA", "#F0E68C", "#BDB76B", "#556B2F", "#6B8E23", "#808000", "#9ACD32", "#32CD32", "#00FF00",
    "#7FFF00", "#7CFC00", "#00FF7F", "#ADFF2F", "#98FB98", "#00FA9A", "#90EE90", "#66CDAA", "#20B2AA",
    "#3CB371", "#2E8B57", "#008B8B", "#008080", "#40E0D0", "#48D1CC", "#AFEEEE", "#7FFFD4", "#00CED1",
    "#5F9EA0", "#4682B4", "#B0E0E6", "#B0C4DE", "#ADD8E6", "#87CEEB", "#87CEFA", "#00BFFF", "#1E90FF",
    "#6495ED", "#4169E1", "#0000FF", "#0000CD", "#00008B", "#000080", "#191970", "#8A2BE2", "#9370DB",
    "#8B008B", "#9400D3", "#9932CC", "#BA55D3", "#800080", "#D8BFD8", "#DDA0DD", "#DA70D6", "#EE82EE",
    "#FF00FF", "#FF00FF", "#FF1493", "#FF69B4", "#FFB6C1", "#FFA07A", "#FF7F50", "#FF6347", "#FF4500",
    "#FF0000", "#DC143C", "#B22222", "#A52A2A", "#8B0000", "#800000"
];
  
  export default Server;