import React from 'react'
import { Dropdown } from 'react-bootstrap'


class ContextMenu extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            items: this.props.items,
            disabled: this.props.items.length == 0
        }
    }


    render() {

        const dropdownBox = {
            float: "left",
            paddingLeft: "0px",
            marginTop: "-7px",
        }

        const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
            <a
              href=""
              ref={ref}
              onClick={e => {
                e.preventDefault();
                onClick(e);
              }}
            >
              {children}
              <span className="threedots" />
            </a>
          ));

        return(
            <React.Fragment>

                <div style={dropdownBox}>
                    <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} key={1} />
                        <Dropdown.Menu size="sm"> 
                            {this.state.items.map((value, index) => 
                                {
                                    if(!value.displayText)
                                        return ( <Dropdown.Divider /> )
                                    return (
                                        <Dropdown.Item key={index} onClick={value.callback}>{value.displayText}</Dropdown.Item>
                                    )
                                }
                            )} 
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </React.Fragment>
        )
    }
}

export default ContextMenu