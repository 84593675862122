import React from 'react'
import SelectInput from '../controls/selectInput'
import { Row, Col } from 'react-bootstrap'
import { Consumer } from  '../../context'

class CategoryEditor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rawCategoryData: [],
            categoryDisplayOptions: [],
            subCategoryDisplayOptions: [],
            selectedCategoryId: null,
            selectedSubCategoryId: null,
        }

        this.populateListsBasedOnSelectedSubCategoryId = this.populateListsBasedOnSelectedSubCategoryId.bind(this)
        this.getSortedCategories = this.getSortedCategories.bind(this)
    }

    // When component mounts, get the categories (raw) and setup the Select list data.
    componentDidMount() {
        // Get the raw category data from the store. This is the full list of categories and their subcategories.
        this.context.getCategories(false)
            .then((categoriesReply) => {
                // Store the raw categories, and create the initial list of Category display options.
                // Then also set the selected Category, if one is selected.
                this.setState({
                    rawCategoryData: categoriesReply,
                    categoryDisplayOptions: this.getSortedCategories(categoriesReply)
                }, () => {  
                    // And then if there's a selected sub category id, populate the drop downs accordingly.
                    this.populateListsBasedOnSelectedSubCategoryId(this.props.value)
                })
            })
    }


    getSortedCategories(data) {
        return data
        .filter((item) => (item.isDeleted === false || item.id === this.state.selectedCategoryId )) // Only deleted items, and the selected one 
        .sort(function(a, b) {
            const textA = a.name.toUpperCase();
            const textB = b.name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
        .map(item => (
            { id: item.id, value: item.name }
        ))
    }

    handleControlChange(fieldName, value) {
        // If the category changed, we need to repopulate the subcategories based on the selected category, 
        // and then reset the selected sub category to null.
        if(fieldName === "selectedCategoryId") {
            const selectedCategory = this.state.rawCategoryData
                .find((item) => item.id === value)

            const subCategories = selectedCategory
                .subcategories
                .filter((item) => (item.isDeleted === false || item.id === this.state.selectedSubCategoryId))
                .map(item => ({ id : item.id, value: item.name }))

            this.setState(
                { 
                    subCategoryDisplayOptions: subCategories, 
                    selectedSubCategoryId: null,
                    selectedCategoryId: value 
                }, () => 
                { 
                    this.props.onChange(null)
                }
            )
        }
        else
        {
            this.setState({
                selectedSubCategoryId: value
            }, () => { 
                this.props.onChange(value)
         })
        }
    }

    componentWillReceiveProps(nextProps) {
        if(this.state.selectedSubCategoryId !== nextProps.value && nextProps.value !== undefined)
        {
            this.populateListsBasedOnSelectedSubCategoryId(nextProps.value)
        }
    }

    populateListsBasedOnSelectedSubCategoryId(subCategoryId) {
       // we need to work out the category, based on the selected sub category.

       // To do this, we need to find the selected sub category, but iterating through all the categories.
       this.state.rawCategoryData.forEach(element => {
           // If this category has subcategories....
            if(element.subcategories) {
                // See if we can find the subcategory in the list...
                const sub = element.subcategories.find((item) => item.id === subCategoryId)
                
                // If we found it....
                if(sub) {
                    // Setup some east to use variables...
                    const selectedCategoryId = sub.categoryId
                    const subCategories = element.subcategories
                    const selectedSubCategoryId = sub.id

                    // Build the SubCategory list...
                    const subCategoryList = subCategories.map((item) => ({id: item.id, value: item.name}));

                    // And set the state.
                    this.setState(
                        {  // First, set the display list...
                            subCategoryDisplayOptions: subCategoryList,
                        }, () => { // then set the selected options...
                                this.setState({
                                    selectedSubCategoryId: selectedSubCategoryId,
                                    selectedCategoryId: selectedCategoryId
                                }, () => { // then handle the changes.
                                    this.props.onChange(selectedSubCategoryId)
                                })
                            }
                    )
                    return;
                }
            }
        });
    }


    render() {

        const displayCategoryLabel = this.props.prefix ? `${this.props.prefix} Category` : 'Category'
        const displaySubCategoryLabel = this.props.prefix ? `${this.props.prefix} Sub Category` : 'Sub Category'

        return (
            <React.Fragment>
                <Row>
                    <Col md="6" xs="12">
                        <SelectInput
                            name="categoryId" 
                            value={this.state.selectedCategoryId} 
                            label={displayCategoryLabel} 
                            help="Select a category" 
                            options={this.state.categoryDisplayOptions} 
                            onChange={(value) => this.handleControlChange('selectedCategoryId', value)} 
                            isViewMode = {this.props.isViewMode}
                        />
                    </Col>
                    <Col md="6" xs="12">
                        <SelectInput
                            name="subCategoryId" 
                            label={displaySubCategoryLabel}
                            value={this.state.selectedSubCategoryId} 
                            help="Select a sub category" 
                            options={this.state.subCategoryDisplayOptions}
                            prerequisit="Select a Category first" 
                            onChange={(value) => this.handleControlChange("selectedSubCategoryId", value)}
                            isViewMode = {this.props.isViewMode}
                        /> 
                    </Col>
                  </Row>
            </React.Fragment>
        )
    }

}
CategoryEditor.contextType = Consumer;
export default CategoryEditor