import React from 'react'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import {getDayNameFromDate, getMonthNameFromDate, getDayNumberFromDate} from '../Helpers/Formatter'
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap'
import { Consumer } from  '../context'
import { withToastManager, } from 'react-toast-notifications';
import LoaderDisplay from './controls/loaderDisplay';
import { DataTypes, ButtonIcons } from '../Constants'

import TextInput from './controls/textInput'
import DateInput from './controls/dateInput'
import SelectInput from './controls/selectInput'
import CurrencyInput from './controls/currencyInput'
import AccountsEditor from './subcomponents/accountsEditor';
import CategoryEditor from './subcomponents/categoryEditor'
import BudgetEditor from './subcomponents/budgetEditor'
import Button from './controls/button'

import cloneDeep from 'lodash/cloneDeep';

import ScheduledFutureList from './scheduledFutureList';
import ScheduledPastList from './scheduledPastList'
import ScheduleDateEditor from './scheduleDateEditor'
import ScheduleAmountEditor from './scheduleAmountEditor'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import ReactGA from 'react-ga';

class ScheduledTransaction extends React.Component {
  constructor(props) {
    super(props);
 
    this.state = {
      isLoading: false,
      isBusy: false,
      isNew: true,
      scheduledDisplayCount: 5,
      eventAmountEditorShowing: false,
      eventDateEditorShowing: false,
      selectedEvent: null,
      isViewMode: false,
      originalData: null,
      schedule: {
        recurrenceTypeId: null,
        scheduleStart: null,
        recurrenceEndTypeId: 1,
        scheduleEnd: null,
        numberOfTransactions: null,
        description: null,
        transactionTypeId: 1,
        subcategoryId: null,
        budgetId: null,
        creditAccount: null,
        debitAccount: null,
        id: null,
        schedule: [],
        dayNumber: null,
        monthNumber: null,
        weekdayOnlyTypeId: 1 // Default to 1, which is 'Never'
      },

      transactionTypes: [
        {id:1, value:"Payment"}, 
        {id:2, value:"Deposit"}, 
        {id:3, value:"Transfer"}
      ],
      recurrenceTypes: [
        {id:1, value:"Once"}, 
        {id:2, value:"Weekly"}, 
        {id:3, value:"Fortnightly"},
        {id:4, value:"Monthly"},
        {id:5, value:"Every 2 months"},
        {id:6, value:"Quarterly"},
        {id:7, value:"Half Yearly"},
        {id:8, value:"Annually"}
      ],
      recurrenceEndTypes: [
        {id:1, value:"Never"}, 
        {id:2, value:"Number of Payments"}, 
        {id:3, value:"Date"},
      ],
      weekdayOnlyTypes: [
        {id:1, value:"No"},
        {id:2, value:"Preceding Friday"},
        {id:3, value:"Following Monday"}
      ]
    }

    this.showEventAmountEditor = this.showEventAmountEditor.bind(this)
    this.showEventDateEditor = this.showEventDateEditor.bind(this)
    this.loadSchedule = this.loadSchedule.bind(this)
    this.handleModalReply = this.handleModalReply.bind(this)
    this.removeVariation = this.removeVariation.bind(this)
    this.skipPayment = this.skipPayment.bind(this)
    this.unSkipPayment = this.unSkipPayment.bind(this)

  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    this.loadSchedule()
  }

  
  handleModalReply(didSave) {
    if(didSave) {
      this.loadSchedule()
    }
  }
    

  loadSchedule() {
    const selectedId = this.props.match.params.id
    if(selectedId) {
      this.setState( {
        isViewMode: true,
        selectedId: selectedId,
        isLoading: true,
        isNew: false,
        eventAmountEditorShowing: false,
        eventDateEditorShowing: false
      })
      
      this.context.getSchedule(selectedId)
        .then((result) => {
          const schedule = {
            recurrenceTypeId : result.data.recurrenceTypeId,
            scheduleStart : result.data.scheduleStart,
            recurrenceEndTypeId : result.data.recurrenceEndTypeId,
            scheduleEnd : result.data.scheduleEnd,
            numberOfTransactions : result.data.numberOfTransactions,
            description: result.data.description,
            transactionTypeId: result.data.transactionTypeId,
            subcategoryId: result.data.subcategoryId,
            budgetId: result.data.budgetId,
            creditAccountId: result.data.creditAccountId,
            debitAccountId: result.data.debitAccountId,
            id: result.data.id,
            amount: result.data.amount,
            schedule: result.data.schedule,
            dayNumber: result.data.dayNumber,
            monthNumber: result.data.monthNumber,
            weekdayOnlyTypeId: result.data.weekdayOnlyTypeId

          }

          const upcoming = schedule.schedule.filter((item) => item.isPayable)
          const passed = schedule.schedule.filter((item) => !item.isPayable).sort(function(a,b) {return new Date(b.date) - new Date(a.date)})




          this.setState({
            isLoading: false,
            schedule,
            upcoming,
            passed,
            originalData: cloneDeep(schedule)
          })
        })
    }
  } 

  handleChange(fieldName, value) {
      const schedule = this.state.schedule
      schedule[fieldName] = value

      if(fieldName === "debitAccountId" || fieldName === "creditAccountId") {
        const selectedAccount = this.context.cacheStore.allAccountsList.find((item) => item.id === value)
        schedule.subcategoryId = selectedAccount.defaultSubcategoryId;
        schedule.budgetId = selectedAccount.defaultBudgetId
      }

      this.setState({schedule : schedule})
  }

  moreClick() {
    this.setState({
      scheduledDisplayCount: this.state.scheduledDisplayCount + 5
    })
  }


  saveClick() {
    this.setState({
      isBusy: true
    })
    const { toastManager } = this.props;
    this.context.saveSchedule(this.state.schedule)
    .then((reply) => {
      this.setState({
        isBusy: false,
      })
      if(reply.ok) {
        this.setState({
          isViewMode: true,
          originalData: cloneDeep(this.state.schedule)
        })
        toastManager.add(reply.message, {
          appearance: 'success',
          autoDismiss: true,
          pauseOnHover: false,
        });
      } 
      else
      {
        toastManager.add(reply.message, {
          appearance: 'error',
          autoDismiss: true,
          pauseOnHover: false,
        });
      } 
    })
  }

  skipPayment(paymentNumber) {

    const { toastManager } = this.props;
    
    this.context.skipScheduledTransaction(this.state.selectedId, paymentNumber)
        .then((result) => {
            if(result.ok) {
                this.loadSchedule()
                toastManager.add(result.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    pauseOnHover: false,
                });
            }
            else 
            {
                toastManager.add(result.message, {
                    appearance: 'error',
                    autoDismiss: true,
                    pauseOnHover: false,
                });
            }
       })
  }

  unSkipPayment(paymentNumber) {

    const { toastManager } = this.props;
    
    this.context.unSkipScheduledTransaction(this.state.selectedId, paymentNumber)
        .then((result) => {
            if(result.ok) {
                this.loadSchedule()
                toastManager.add(result.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    pauseOnHover: false,
                });
            }
            else 
            {
                toastManager.add(result.message, {
                    appearance: 'error',
                    autoDismiss: true,
                    pauseOnHover: false,
                });
            }
       })
  }

  cancelClick() {
    
    this.setState({
      isViewMode: true,
      schedule: cloneDeep(this.state.originalData)
    })

  }

  // Performs the delete action
  delete ()  {
    this.setState({
      isBusy: true
    })

    const { toastManager } = this.props;
    this.context.deleteSchedule(this.state.selectedId)
      .then((reply) => {
        this.setState({
          isBusy: false
        })
        if(reply.ok) {
          this.props.history.goBack();
          toastManager.add(reply.message, {
            appearance: 'success',
            autoDismiss: true,
            pauseOnHover: false,
          });
        } 
        else
        {
          toastManager.add(reply.message, {
            appearance: 'error',
            autoDismiss: true,
            pauseOnHover: false,
          });
        }
      });
  }

  // Confirm that the user wants to delete the selected Schedule
  deleteClick() {
  
    confirmAlert({
      customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
                <h1>Delete Schedule</h1>
                <p>Confirm you want to delete this Schedule</p>
                <Button onClick={onClose} text="Cancel" variant="info" />&nbsp;
                <Button onClick={() =>{ this.delete(); onClose()} } text="Delete" varient="success" />
            </div>
          );
       }    
    })
  
  }

  showEventAmountEditor(paymentNumber) {
    const selectedItem = this.state.schedule.schedule.filter((item) => item.paymentNumber === paymentNumber)
        this.setState({
          eventAmountEditorShowing: true,
          selectedEvent: selectedItem[0]
        })
  }

  showEventDateEditor(paymentNumber) {
    const selectedItem = this.state.schedule.schedule.filter((item) => item.paymentNumber === paymentNumber)
        this.setState({
          eventDateEditorShowing: true,
          selectedEvent: selectedItem[0]
        })
    
  }

  removeVariation(variationId) {
    confirmAlert({
      customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
                <h1>Remove Schedule Variation</h1>
                <p>Confirm you want to remove this Variation</p>
                <Button onClick={onClose} text="Cancel" variant="info" />&nbsp;
                <Button onClick={() =>{ this.removeVariationConfirmed(variationId); onClose()} } text="Delete" varient="success" />
            </div>
          );
       }    
    })
  }

  removeVariationConfirmed(variationId) {
    const { toastManager } = this.props;
    this.context.deleteScheduleVariation(variationId)
      .then((result) => {
        if(result.ok) {
            this.loadSchedule()
            toastManager.add(result.message, {
              appearance: 'success',
              autoDismiss: true,
              pauseOnHover: false,
            });
        }
        else 
        {
          toastManager.add(result.message, {
            appearance: 'error',
            autoDismiss: true,
            pauseOnHover: false,
          });

        }
    })
  }


  render() {

    if(this.state.isLoading) {
      return <LoaderDisplay />
    }

    const showSchedule = () => {
        if(!this.state.schedule.recurrenceTypeId || !this.state.schedule.scheduleStart)
            return "";

        let type = ""
        if(this.state.schedule.recurrenceTypeId) {
            const t = this.state.recurrenceTypes.find((item) => item.id === this.state.schedule.recurrenceTypeId)
            type = t.value
        }

        switch(this.state.schedule.recurrenceTypeId) {
          case 1:
          case 2:
          case 3:
            if(this.state.schedule.scheduleStart) {
              var a = new Date(this.state.schedule.scheduleStart);
              const day = getDayNameFromDate(a)
              return type + " on a " + day
            }
            break;
          case 4:
          case 5:
              if(this.state.schedule.scheduleStart) {
                var d = new Date(this.state.schedule.scheduleStart);
                const day = getDayNumberFromDate(d)
                return type + " on the " + day + " day of the month"
              }
              break;
          default:
              if(this.state.schedule.scheduleStart) {
                var b = new Date(this.state.schedule.scheduleStart);
                const month = getMonthNameFromDate(b)
                const day = getDayNumberFromDate(b)
                return type + ", starting the " + day + ' of ' + month
              }

        }

        
        
    }

    const buttonBarStyle = {
      display: "flex",
      justifyContent: "flex-end"
    }

    const buttonSpacingStyle = {
      padding: "4px",
      float: "left"
    }

    return (
      <React.Fragment >
        <AuthorisedArea showError>
        <div className="borderBoxStyle">  
          <Row>
            <Col>
              <Row>
                <Col>
                    <h5>Scheduled Transaction</h5>
                    <hr />
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <SelectInput
                    readOnly={!this.state.isNew}
                    label="Recurrence"
                    help="How often does this schedule occur"
                    options={this.state.recurrenceTypes}
                    value={this.state.schedule.recurrenceTypeId}
                    onChange={(value)=> this.handleChange("recurrenceTypeId", value)}
                    isViewMode={this.state.isViewMode}
                  />
                </Col>
                <Col md="3">
                  <DateInput
                      readOnly={!this.state.isNew}
                      label="Starting Date"
                      format="dd MMM yyyy"
                      help="The date when the schedule starts"
                      onChange={(value) => this.handleChange("scheduleStart", value)}
                      value={this.state.schedule.scheduleStart}
                      isViewMode={this.state.isViewMode}
                  />
                </Col>
                {this.state.schedule.recurrenceTypeId > 1 && 
                  <Col md="3">
                    <SelectInput
                      label="Ends After"
                      help="How will this schedule end"
                      options={this.state.recurrenceEndTypes}
                      onChange={(value) => this.handleChange("recurrenceEndTypeId", value)}
                      value={this.state.schedule.recurrenceEndTypeId}
                      isViewMode={this.state.isViewMode}
                    />
                  </Col>
                }
                {this.state.schedule.recurrenceEndTypeId === 3 && this.state.schedule.recurrenceTypeId > 1 &&
                  <Col md="3">
                    <DateInput
                        label="Final Payment Date"
                        format="dd MMM yyyy"
                        help="The date of the final payment"
                        onChange={(value) => this.handleChange("scheduleEnd", value)}
                        value={this.state.schedule.scheduleEnd}
                        isViewMode={this.state.isViewMode}
                    />
                  </Col>
                }
                {this.state.schedule.recurrenceEndTypeId === 2 && this.state.schedule.recurrenceTypeId > 1 &&
                  <Col md="2">
                    <TextInput
                        label="Number of Payments"
                        help="How many payments"
                        onChange={(value) => this.handleChange("numberOfTransactions", value)}
                        value={this.state.schedule.numberOfTransactions}
                        dataType={DataTypes.NUMBER}
                        isViewMode={this.state.isViewMode}
                    />
                  </Col>
                }
              </Row>
              <Row>
                <Col md="12">
                    {showSchedule()}
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <TextInput
                      placeholder="Transaction Description"
                      label="Description"
                      value={this.state.schedule.description}
                      onChange={(value) => this.handleChange("description", value)}
                      help="A description of this transaction"
                      isViewMode={this.state.isViewMode}
                  />
                </Col>
                {this.state.schedule.recurrenceTypeId > 1 &&
                  <Col md="2">
                    
                    <SelectInput
                      label="Change Weekend Events"
                      value={this.state.schedule.weekdayOnlyTypeId}
                      onChange={(value) => this.handleChange("weekdayOnlyTypeId", value)}
                      isViewMode={this.state.isViewMode}
                      help="Change Weekend Events?"
                      options={this.state.weekdayOnlyTypes}
                    />
                  </Col>
                }
              </Row>
              <Row>
                <Col md="3">
                  <SelectInput 
                    name="transactionTypeId"
                    value={this.state.schedule.transactionTypeId} 
                    label="Transaction Type" 
                    help="The type of transaction" 
                    options={this.state.transactionTypes}
                    onChange={(value) => this.handleChange("transactionTypeId", value)}
                    isViewMode={this.state.isViewMode}
                  />
                </Col>
                <Col md="7">
                  <AccountsEditor
                      onChange={(fieldName, value) => this.handleChange(fieldName, value)} 
                      transactionTypeId={this.state.schedule.transactionTypeId}
                      debitAccountId={this.state.schedule.debitAccountId}
                      creditAccountId={this.state.schedule.creditAccountId} 
                      isViewMode={this.state.isViewMode}
                    />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <CategoryEditor 
                    onChange = {(value) => this.handleChange("subcategoryId", value)}
                    value = {this.state.schedule.subcategoryId}
                    isViewMode={this.state.isViewMode}
                    />
                </Col>
                <Col md="3">
                    <BudgetEditor
                      onChange = {(value) => this.handleChange("budgetId", value)}
                      value={this.state.schedule.budgetId}
                      isViewMode={this.state.isViewMode}
                      />
                </Col>
              </Row>
              <Row>
                <Col md="2" xs="6">
                  <CurrencyInput
                      help="Scheduled Amount"
                      label="Amount"
                      onChange={(value) => this.handleChange("amount", value)}
                      value={this.state.schedule.amount}
                      isViewMode={this.state.isViewMode}
                      />
                </Col>
              </Row>
              <Row right>
                <Col >
                  <div style={buttonBarStyle}>
                    {!this.state.isViewMode &&
                      <Button 
                        text="Save" 
                        isBusy={this.state.isBusy} 
                        variant="primary" 
                        onClick={() => this.saveClick()} 
                        style={buttonSpacingStyle} 
                        icon={ButtonIcons.SAVE}
                      />
                    }

                    {this.state.isViewMode &&
                      <Button 
                        text="Edit" 
                        isBusy={this.state.isBusy} 
                        variant="primary" 
                        onClick={() => this.setState({ isViewMode: false })} 
                        style={buttonSpacingStyle} 
                        icon={ButtonIcons.EDIT}
                      />
                    }

                    

                    {!this.state.isNew && this.state.isViewMode &&
                      <Button 
                        text="Delete" 
                        isBusy={this.state.isBusy} 
                        variant="outline-danger" 
                        onClick={() => this.deleteClick()} 
                        style={buttonSpacingStyle} 
                        icon={ButtonIcons.DELETE}
                      />
                    }
                    {!this.state.isViewMode &&
                      <Button 
                        text="Cancel" 
                        isBusy={this.state.isBusy} 
                        variant="outline-primary" 
                        onClick={() => this.cancelClick()} 
                        style={buttonSpacingStyle} 
                        icon={ButtonIcons.CANCEL}
                      />
                      }
                      {this.state.isViewMode && 
                        <Button 
                          text="Back" 
                          isBusy={this.state.isBusy} 
                          variant="outline-primary" 
                          onClick={() => this.props.history.goBack() } 
                          style={buttonSpacingStyle} 
                          icon={ButtonIcons.BACK}
                      />
                      }
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          </div>
          <Row className="top-buffer">
            <Col md="6" xs="12">
              {this.state.schedule.id && this.state.upcoming &&
                <div className="borderBoxStyle">  
                  <ScheduledFutureList 
                    schedule={this.state.upcoming} 
                    showAmountEditorClick={this.showEventAmountEditor} 
                    showDateEditorClick={this.showEventDateEditor}
                    removeVariation={this.removeVariation}
                    skipPayment={this.skipPayment} 
                  />
                </div>
              }
            </Col>
            <Col md="6" xs="12">
              {this.state.schedule.id && this.state.passed &&
                <div className="borderBoxStyle">  
                  <ScheduledPastList 
                    schedule={this.state.passed} 
                    unSkipPayment={this.unSkipPayment}
                  />
                </div>
              }
            </Col>
          </Row>
         

          <ScheduleDateEditor 
            event={this.state.selectedEvent} 
            show={this.state.eventDateEditorShowing} 
            callbackMethod={this.handleModalReply}
          />

          <ScheduleAmountEditor 
            event={this.state.selectedEvent} 
            show={this.state.eventAmountEditorShowing} 
            callbackMethod={this.handleModalReply}
          />
          
        </AuthorisedArea>
      </React.Fragment>
    )
  }
}

  ScheduledTransaction.contextType = Consumer;
  export default withRouter(withToastManager(ScheduledTransaction));