import React from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Button } from 'react-bootstrap'
import ControlLabel from './controlLabel'
import ControlText from './controlText';


class SelectInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedItem: null,
            displayOptions: this.buildDisplayOptions(this.props.options),
            componentName: this.props.name,
            isViewMode: this.props.isViewMode
        }
        this.onChange = this.onChange.bind(this);
        this.setSelectedItem = this.setSelectedItem.bind(this);

    }

    buildDisplayOptions(options) {
        const displayOptions = options ? options
        .map((item) => { 
            return { 
                value: item.id, 
                label: item.value
            }
        }) : []

        if(this.props.allowBlank) {
            const blank = {
                value: null,
                label: "None"
            }
            displayOptions.splice(0,0, blank)
        }
        return displayOptions
    }

    componentDidMount() {

        if(this.props.value)
            this.setState({
                selectedItem: this.setSelectedItem(this.props.value)
            })
    }

    onChange(selectedItem) {
        // Set the selected item, and then call the OnChange event to tell the parent that the selected value has changed.
        this.setState(
            { selectedItem }, 
            () => { 
                this.props.onChange(selectedItem.value)
            });
    }

    /*
        returns the text/label for the selected item.
    */
    textBasedOnValue() {
            return this.state.selectedItem ? this.state.selectedItem.label : null;
    }

    handleCreate(input) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h1>Add new Third Party</h1>
                    <p>This will create a new Third Party, "{input}". Continue?</p>
                    <Button onClick={onClose}>No</Button>&nbsp;
                    <Button
                      onClick={() => {
                        onClose();
                      }}
                    >
                      Yes, Create it!
                    </Button>
                  </div>
                );
              }
          });
    }

    // This method handles updates to props that get triggered from the parent.
    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.state) {
            
            const newValue = nextProps.value;
            const currentValue = this.state.selectedItem != null ? this.state.selectedItem.id : null

            let selectedItem = this.state.selectedItem;
            const displayOptions = this.buildDisplayOptions(nextProps.options)

            this.setState({ 
                displayOptions: displayOptions
                        }, () => {
                            // Has the selected item changed?
                            if(newValue !== currentValue) {
                                selectedItem = this.setSelectedItem(newValue)
                            } 
                    
                    this.setState({
                        selectedItem: selectedItem, 
                    });
                });
        }

    }

    setSelectedItem(id) {
        const item = this.state.displayOptions.find((item) => item.value === id);
        if(!item) {
            return {value: null, label: null}
        }
        return item;
    }

    render() {

        const text = this.textBasedOnValue();

        // Transform the options that the control uses to use value and label.
        // This is done, as the options comes in as id and value.





        return (
            <React.Fragment>
                <div>
                    <ControlLabel text={this.props.label} />
                </div>
                {!this.props.isViewMode &&
                    <div>
                    {this.props.isCreatable &&
                        <CreatableSelect
                            isCreatable
                            isLoading={false} 
                            placeholder={this.props.placeholder} 
                            value={this.state.selectedItem}
                            onChange={this.onChange}  
                            options = {this.state.displayOptions}
                            onCreateOption={this.handleCreate}
                            isDisabled={this.props.readOnly}
                        />
                    }

                    { !this.props.isCreatable &&
                        <Select
                            isLoading={false} 
                            placeholder={this.props.placeholder} 
                            value={this.state.selectedItem}
                            onChange={this.onChange}  
                            options = {this.state.displayOptions}
                            isDisabled={this.props.readOnly}
                        />
                    }
                    </div>
                    }
                     {this.props.isViewMode &&
                        <ControlText text={text} />
                    }
                    {!this.props.isViewMode && 
                        <div className="hintStyle">
                            {this.props.help}
                        </div>
                    }
            </React.Fragment>
        )
    }
}

export default SelectInput