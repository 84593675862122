import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Consumer } from  '../../context'
import SelectInput from '../controls/selectInput'
import { AccountTypes, TransactionTypes } from '../../Constants'

class AccountsEditor extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            transactionTypeId: this.props.transactionTypeId,
            selectedDebitAccountId: null,
            selectedCreditAccountId: null,
            debitAccountOptions: [],
            creditAccountOptions: [],
        }
    }

    componentDidMount() {
        this.UpdateAccountSelectionsBasedOnTransactionType(this.props.debitAccountId, this.props.creditAccountId)
    }

    componentWillReceiveProps(nextProps) {

        // if(nextProps.isViewMode !== this.state.isViewMode)
        // {
        //     this.setState({
        //         isViewMode: nextProps.isViewMode
        //     });
        // }

        if(nextProps.transactionTypeId !== this.state.transactionTypeId || nextProps.debitAccountId !== this.state.debitAccountId || nextProps.creditAccountId !== this.state.creditAccountId) {
            // Did the transaction type change? If so, we need to refresh the selects, before assigning any potential new selected items.
            if(nextProps.transactionTypeId !== this.state.transactionTypeId) {
                this.setState({ 
                    transactionTypeId: nextProps.transactionTypeId,
                    }, () => this.UpdateAccountSelectionsBasedOnTransactionType(nextProps.debitAccountId, nextProps.creditAccountId)
                        .then(() => {
                            this.setState({
                                debitAccountId: nextProps.debitAccountId,
                                creditAccountId: nextProps.creditAccountId
                            })
                        })
                )
                return
            }

            if(nextProps.debitAccountId !== this.state.debitAccountId || nextProps.creditAccountId !== this.state.creditAccountId) {
                this.setState({
                    debitAccountId: nextProps.debitAccountId,
                    creditAccountId: nextProps.creditAccountId
                })
            }
        }
    }

    // Method to update the drop downs. Accepts account ids that MUST be included. Handles when editing a transaction where the account is no longer active.
    UpdateAccountSelectionsBasedOnTransactionType(debitAccountId, creditAccountId) {
        return new Promise((resolve) => {

            let creditType = AccountTypes.BANKACCOUNT;
            let debitType = AccountTypes.BANKACCOUNT;
        
            switch(this.state.transactionTypeId) {
            case TransactionTypes.PAYMENT:
                debitType = AccountTypes.BANKACCOUNT;
                creditType = AccountTypes.THIRDPARTY;
                break;
            case TransactionTypes.DEPOSIT:
                debitType = AccountTypes.THIRDPARTY;
                creditType = AccountTypes.BANKACCOUNT;
                break;
            default:
                creditType = AccountTypes.BANKACCOUNT;
                debitType = AccountTypes.BANKACCOUNT;
            }
        
            this.context.getAllAccounts(false)
                .then((result) => {
                    const debitAccountOptions = result
                        .filter(item => 
                                    item.accountType === debitType && // Must be right type.
                                    (item.isActive === true || item.id === creditAccountId  || item.id === debitAccountId) // Only active items, or if it's the selected one
                                )
                        .sort(function(a, b) {
                            const textA = a.name.toUpperCase();
                            const textB = b.name.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        })
                        .map(item => ({ id: item.id, value: item.name }))
                
                    const creditAccountOptions = result
                        .filter(item => 
                                    item.accountType === creditType && 
                                    (item.isActive === true || item.id === creditAccountId  || item.id === debitAccountId)
                                )
                        .sort(function(a, b) {
                            const textA = a.name.toUpperCase();
                            const textB = b.name.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        })
                        .map(item => ({ id: item.id, value: item.name }))
            
                    this.setState({
                    debitAccountOptions,
                    creditAccountOptions,
                    creditAccountId: null,
                    debitAccountId: null
                    })
                    resolve()
                });
            })
      }


    handleControlChange(fieldName, value) {
        this.props.onChange(fieldName, value);
    }


    render() {

        return(
            <React.Fragment>
                <Row>
                  <Col lg="6" xs="12">
                    <SelectInput 
                      name="debitAccountId"
                      value={this.state.debitAccountId} 
                      label="From" 
                      help="Select an account" 
                      actionButtonText="New" 
                      onChange={(value) => this.handleControlChange("debitAccountId", value)}
                      options={this.state.debitAccountOptions}
                      isViewMode = {this.props.isViewMode}
                      />
                  </Col>
                  <Col lg="6" xs="12">
                    <SelectInput 
                      name="creditAccountId"
                      value={this.state.creditAccountId} 
                      label="To" 
                      help="Select an account"  
                      actionButtonText="New"  
                      onChange={(value) => this.handleControlChange("creditAccountId", value)}
                      options={this.state.creditAccountOptions}
                      isCreatable={true}
                      isViewMode = {this.props.isViewMode}
                    />
                  </Col>
                  </Row>
            </React.Fragment>
        )
    }
}

AccountsEditor.contextType = Consumer
export default AccountsEditor