import React from 'react'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import { withRouter } from 'react-router-dom';
import { Form, Row, Col, Modal } from 'react-bootstrap'
import { Consumer } from  '../context'
import { withToastManager, } from 'react-toast-notifications';
import { TransactionTypes, ButtonIcons }  from '../Constants'
import AccountEditor from './subcomponents/accountsEditor'
import Attachments from './attachments'
import LoaderDisplay from './controls/loaderDisplay';
import ControlLabel from './controls/controlLabel'
import { confirmAlert } from 'react-confirm-alert'; 
import cloneDeep from 'lodash/cloneDeep';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { formatMoney } from '../Helpers/Formatter'

import TextInput from './controls/textInput'
import DateInput from './controls/dateInput'
import SelectInput from './controls/selectInput'
import Button from './controls/button'

import TransactionLineEditor from './transactionLineEditor'

import ReactGA from 'react-ga';

import { configureAnchors, goToAnchor } from 'react-scrollable-anchor'
import ScrollableAnchor from 'react-scrollable-anchor';

class TransactionEditor extends React.Component {

  static contextType = Consumer;

  constructor(props, context) {
    super(props);
    this.context = context;
 
    this.state = {
      isLoading: true,
      isBusy: false,
      isViewMode: false,
      uploadVisible: false,
      selectedFile: null,
      transaction: {
        id: null,
        transactionDate: this.context.lastSelectedDate,
        transactionTypeId: TransactionTypes.PAYMENT,
        debitAccountId: null,
        creditAccountId: null,
        description: null,
        transactionLines : [],
        scheduleId: null,
        paymentNumber: null,
        attachments: []
      },

      transactionTypes: [
        {id:1, value:"Payment"}, 
        {id:2, value:"Deposit"}, 
        {id:3, value:"Transfer"}
      ],
    }

    this.handleControlChange = this.handleControlChange.bind(this);
    this.addLine = this.addLine.bind(this);
    this.removeLine = this.removeLine.bind(this);

  }

  componentDidMount() {
    configureAnchors({offset: -70, scrollDuration: 50})
    
    goToAnchor('mainSection', false)
    
    ReactGA.pageview(window.location.pathname);
    // Ensure we have data... so this will make sure the context is up to date.

    this.loadData()
  }

  loadData() {
    this.context.getAllAccounts(false)
      .then(() => { // Once we have all the accounts... see if we need to load up a transaction

        // Check if we have an ID to edit. This is in the props.
        const selectedId = this.props.match.params.id
        const scheduleId = this.props.match.params.scheduleId
        const paymentNumber = parseInt(this.props.match.params.paymentNumber)

        // check we don't have bad data..
        if(selectedId && scheduleId)
        {
          const { toastManager } = this.props;
          this.props.history.goBack();
          toastManager.add("Cannot edit both a transaction, and a Schedule.", {
            appearance: 'error',
            autoDismiss: true,
            pauseOnHover: false,
          });
          return
        }

        if(selectedId || scheduleId) {

          // We're editing something, so let's set the loading screen
          this.setState({
            isLoading: true,
          })

          // We're editing a transaction.
          if(selectedId) {
            this.context.getTransaction(selectedId)
              .then((result) => {
                if(result.ok === false) {
                  const { toastManager } = this.props;
                  this.props.history.goBack();
                  toastManager.add(result.message, {
                    appearance: 'error',
                    autoDismiss: true,
                    pauseOnHover: false,
                  });
                  return        
                }

                const transaction = {
                  id: selectedId,
                  transactionTypeId: result.data.transactionTypeId,
                  transactionDate: result.data.date,
                  debitAccountId: result.data.debitAccountId,
                  creditAccountId: result.data.creditAccountId,
                  description: result.data.description,
                  transactionLines: result.data.transactionLines,
                  attachments: result.data.attachments
                }

                // Transaction loaded... so set the state and make sure we remember the initial state.
                this.setState({
                  transaction,
                  originalData: cloneDeep(transaction),
                  isViewMode: true,
                  isLoading: false
                })
            })
          }

          // We're paying a scheduled transaction which hasn't been created yet. So need to get the data
          if(scheduleId)
          {
            this.context.getTransactionForSchedule(scheduleId, paymentNumber)
              .then((data) => {

                if(data.ok === false) {
                  const { toastManager } = this.props;
                  this.props.history.goBack();
                  toastManager.add(data.message, {
                    appearance: 'error',
                    autoDismiss: true,
                    pauseOnHover: false,
                  });
                  return        
                }

                const transaction = {
                  transactionTypeId: data.transactionTypeId,
                  transactionDate: data.date,
                  debitAccountId: data.debitAccountId,
                  creditAccountId: data.creditAccountId,
                  description: data.description,
                  transactionLines: data.transactionLines,
                  scheduleId: scheduleId,
                  paymentNumber: paymentNumber
                }

                this.setState({
                  transaction,
                  originalData: cloneDeep(transaction),
                  isViewMode: false,
                  isLoading: false
                })
              })
          }
        }
        else
        {
          this.addLine();

          this.context.getDefaultAccount()
            .then((defaultAccountId) => {
              let transaction = this.state.transaction
              transaction.debitAccountId = defaultAccountId;

         
              this.setState({
                transaction,
                originalData: cloneDeep(transaction),
                isLoading: false
              })
            })
        }
    })

  }


  handleControlChange(fieldName, value) {
    if(fieldName === "transactionTypeId") {

      let currentDr = this.state.transaction.debitAccountId
      let currentCr = this.state.transaction.creditAccountId
      let newDr = null
      let newCr = null

      if(this.state.transaction.transactionTypeId === TransactionTypes.PAYMENT && value === TransactionTypes.DEPOSIT)
      {
        newDr = currentCr
        newCr = currentDr
      } 
      else
      if(this.state.transaction.transactionTypeId === TransactionTypes.PAYMENT && value === TransactionTypes.TRANSFER) {
        newDr = currentDr
        newCr = null
      }
      else
      if(this.state.transaction.transactionTypeId === TransactionTypes.DEPOSIT && value === TransactionTypes.PAYMENT) {
        newDr = currentCr
        newCr = currentDr
      }
      if(this.state.transaction.transactionTypeId === TransactionTypes.DEPOSIT && value === TransactionTypes.TRANSFER) {
        newDr = null
        newCr = currentCr
      }
      if(this.state.transaction.transactionTypeId === TransactionTypes.TRANSFER && value === TransactionTypes.DEPOSIT) {
        newDr = null
        newCr = currentDr
      }
      if(this.state.transaction.transactionTypeId === TransactionTypes.TRANSFER && value === TransactionTypes.PAYMENT) {
        newDr = currentDr
        newCr = null
      }
      
      let transaction = this.state.transaction
      transaction.transactionTypeId = value
      transaction.creditAccountId = newCr
      transaction.debitAccountId = newDr
   


      this.setState({ 
        transaction
      });
        return
    }

    if(fieldName === "debitAccountId" || fieldName === "creditAccountId") {
      const selectedAccount = this.context.cacheStore.allAccountsList.find((item) => item.id === value)
      let lines = [...this.state.transaction.transactionLines]
      lines[0].categoryId = selectedAccount.defaultCategoryId
      lines[0].subCategoryId = selectedAccount.defaultSubcategoryId
      lines[0].budgetId = selectedAccount.defaultBudgetId

      let transaction = this.state.transaction
      transaction.transactionLines = lines
      transaction[fieldName] = value

      this.setState({
        transaction
      });

    }

    if(fieldName === 'transactionDate') {
      this.context.setLastSelectedDate(value)
    }

    if(fieldName === 'amount' || fieldName === 'subCategoryId' || fieldName === 'budgetId' || fieldName === 'categoryId') {
    
      let transaction = this.state.transaction
   
      let lines = [...transaction.transactionLines];
      lines[0][fieldName] = value;

      transaction.transactionLines = lines

      this.setState({transaction})
    }
    else
    {
      let transaction = this.state.transaction
      transaction[fieldName] = value
      this.setState({transaction});
    }     
  }

  onChangeFileHandler(e) {
    e.preventDefault();

    this.setState({
      selectedFile: e.target.files[0]
    })
  }

  handleUpload() {

    const formData = new FormData() 
    formData.append('formFile', this.state.selectedFile)
    formData.append('description', 'Hardcoded')
    formData.append('parentId', this.state.transaction.id)

    this.context.addAttachment(this.state.transaction.id, formData)
      .then((result) => {
        const { toastManager } = this.props;

        if(result) {
          toastManager.add(result.message, {
            appearance: 'success',
            autoDismiss: true,
            pauseOnHover: false,
          });
        }  
        this.setState({
          uploadVisible: false
        })
      })
  }

  handleCloseAttachment() {
    this.setState({
      uploadVisible: false
    })
  }

  delete() {
    this.setState( {
      isBusy: true
    })

    this.context.deleteTransaction(this.state.transaction.id)
      .then((result) => {
        this.setState({
          isBusy: false
        })
          const { toastManager } = this.props;
          if(result) {
            this.props.history.goBack();
            toastManager.add("Transaction Deleted Successfully.", {
              appearance: 'success',
              autoDismiss: true,
              pauseOnHover: false,
            });
          } 
        })
    }



  handleControlLineChange(index, fieldName, value) {

    let transaction = this.state.transaction


    let lines = [...transaction.transactionLines]
    let line = lines[index]
    line[fieldName] = value
    lines[index] = line

    transaction.transactionLines = lines

    this.setState({ transaction })
  }

  addLine() {

    let transaction = this.state.transaction

    // Try pre populate the new item with the previous category, as usually we only change the budget.
    let prevSubCategoryId = null
    if(transaction.transactionLines.length > 0) {
      const prevLine = transaction.transactionLines[transaction.transactionLines.length - 1] // Get the last item 
      prevSubCategoryId = prevLine.subCategoryId
    }


    let lines = [...transaction.transactionLines, {
      amount: null,
      budgetId: null,
      categoryId: null,
      subCategoryId: prevSubCategoryId,
      id: null,
    }]

    transaction.transactionLines = lines

    this.setState({ transaction })
  }

  removeLine(index) {
    const transaction = cloneDeep(this.state.transaction)
    var lines = transaction.transactionLines;
    lines.splice(index,1)
    transaction.transactionLines = lines

    this.setState({ transaction })
  }

  render() {
    const cancelClick = () => {

      if(this.state.transaction.id)
      {
        this.setState({
          isViewMode: true,
          transaction: cloneDeep(this.state.originalData)
        })
      }
      else
      {
        this.props.history.goBack()
      }
    }

    const backClick = () => {
      this.props.history.goBack();
    }

    const attachmentClick = () => {
      this.setState({
        uploadVisible: true
      })
    }
    
    const deleteClick = () => {
      confirmAlert({
        customUI: ({ onClose }) => {
            return (
              <div className='custom-ui'>
                  <h1>Delete Transaction</h1>
                  <p>Confirm you want to delete this transaction</p>
                  <Button onClick={onClose} text="Cancel" variant="info" />&nbsp;
                  <Button onClick={() =>{ this.delete(); onClose()} } text="Delete" varient="success" />
              </div>
            );
          }
      });
      
    }

    const saveClick = () => {
      this.setState({
        isBusy: true
      })

      const saveRequest = this.state.transaction

      this.context.saveTransaction(saveRequest)
        .then((result) => {
         
          const { toastManager } = this.props;
          
          if(result.ok) {
            this.props.history.goBack();
            toastManager.add(result.message, {
              appearance: 'success',
              autoDismiss: true,
              pauseOnHover: false,
            });
          } 
          else 
          {
            toastManager.add(result.message, {
              appearance: 'error',
              autoDismiss: true,
              pauseOnHover: false,
            });

            this.setState({
              isBusy: false
            })
  
          }

        })
    }

    if(this.state.isLoading) {
      return <LoaderDisplay />
    }

    const transbox = {
      borderStyle: "solid",
      borderColor: "lightgrey",
      borderWidth: "1px",
      padding: "5px",
      marginBottom: "10px"
    }

    const buttonSpacingStyle = {
      padding: "4px",
      float: "left"
    }

    const buttonBarStyle = {
      display: "flex",
      justifyContent: "flex-end"
    }

    const transactionValueStyle = {
      fontSize: "32px"
    }

    const transactionTotal = () => {
      let value = 0;
      this.state.transaction.transactionLines.forEach(item => {
        value = value + item.amount
      })
      return value
    }

    return (
          <React.Fragment >
            <AuthorisedArea showError>
              <div  className="borderBoxStyle">
              <Row>
                <Col>
                <Row>
                <Col>
                <ScrollableAnchor id={'mainSection'}>
                    <h5>Transaction Editor</h5>
                    </ScrollableAnchor>
                    <hr />
                </Col>
              </Row>
              <Form>
                <Row>
                  <Col md="6" xs="5">
                    <DateInput 
                      className="form-control" 
                      value={this.state.transaction.transactionDate} 
                      label="Transaction Date" 
                      help="The date the transaction occured" 
                      onChange={(e) => this.handleControlChange("transactionDate", e)}
                      isViewMode = {this.state.isViewMode}
                    />
                  </Col>
                  <Col md="6" xs="7" className="text-right">
                      <ControlLabel text="Total Value" /><br />
                      <span style={transactionValueStyle}>$ {formatMoney(transactionTotal())}</span>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" xs="12">
                    <TextInput 
                      value={this.state.transaction.description} 
                      label="Transaction Description" 
                      onChange = {(value) => this.handleControlChange("description", value)} 
                      help="A description of this transaction"
                      isViewMode = {this.state.isViewMode}
                      />
                  </Col>
                </Row>
                <Row>
                  <Col md="3" xs="6">
                    <SelectInput 
                      name="transactionTypeId"
                      value={this.state.transaction.transactionTypeId} 
                      label="Transaction Type" 
                      help="The type of transaction" 
                      options={this.state.transactionTypes}
                      onChange={(value) => this.handleControlChange("transactionTypeId", value)}
                      isViewMode = {this.state.isViewMode}
                    />
                  </Col>
                  <Col md="9">
                    <AccountEditor 
                      onChange={this.handleControlChange} 
                      transactionTypeId={this.state.transaction.transactionTypeId}
                      debitAccountId={this.state.transaction.debitAccountId}
                      creditAccountId={this.state.transaction.creditAccountId}
                      isViewMode ={this.state.isViewMode}
                    />
                  </Col>
                </Row>
                
                {this.state.transaction.transactionLines.map((line, index) => 
                  <React.Fragment>
                    <div style={transbox}>
                      <TransactionLineEditor
                        key={index}
                        lineIndex={index}
                        onChange={(index, fieldName, value) => this.handleControlLineChange(index, fieldName, value)}
                        subCategoryId={line.subCategoryId}
                        budgetId={line.budgetId}
                        amount={line.amount}
                        allowDelete={index > 0}
                        onDelete={() => this.removeLine(index)}
                        isViewMode ={this.state.isViewMode}
                      />
                    </div>
                  </React.Fragment>
                )} 
                {!this.state.isViewMode && 
                  <Row>
                    <Col md="2">
                      <Button 
                        onClick={this.addLine} 
                        text="Add Split" 
                        variant="info"
                        size="sm"
                        icon={ButtonIcons.ADD}
                      />
                    </Col>
                  </Row>
                }
                <Row >
                  <Col>
                    <div style={buttonBarStyle}>
                    {this.state.isViewMode && 
                          <Button  
                            onClick={() => backClick()} 
                            text="Back"
                            variant="outline-primary"
                            style={buttonSpacingStyle}
                            icon={ButtonIcons.BACK}
                          />
                      }


                      {!this.state.isViewMode &&
                          <Button  
                            onClick={cancelClick} 
                            text="Cancel"
                            variant="outline-primary" 
                            isBusy={this.state.isBusy}
                            style={buttonSpacingStyle}
                            icon={ButtonIcons.CANCEL}
                          />
                        }


{this.state.isViewMode && 
                          <Button  
                            onClick={attachmentClick} 
                            text="Add Attachment"
                            variant="outline-primary" 
                            isBusy={this.state.isBusy}
                            style={buttonSpacingStyle}
                            icon={ButtonIcons.ATTACH}
                          /> 
                      }


                        {this.state.isViewMode && 
                          <Button  
                            onClick={deleteClick} 
                            text="Delete"
                            variant="outline-danger" 
                            isBusy={this.state.isBusy}
                            style={buttonSpacingStyle}
                            icon={ButtonIcons.DELETE}
                          /> 
                      }


                      {!this.state.isViewMode && 
                          <Button  
                            isBusy={this.state.isBusy}
                            onClick={saveClick} 
                            text="Save"
                            variant="primary"
                            style={buttonSpacingStyle}
                            icon={ButtonIcons.SAVE}
                          />
                      }                        
                      
                      {this.state.isViewMode && 
                          <Button  
                            onClick={() => { this.setState({ isViewMode: false}); }} 
                            text="Edit"
                            variant="primary"
                            style={buttonSpacingStyle}
                            icon={ButtonIcons.EDIT}
                          />
                      }
</div>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
        {this.state.isViewMode && this.state.transaction.attachments && this.state.transaction.attachments.length &&
        <div  className="borderBoxStyle top-buffer">
        <Attachments attachments={this.state.transaction.attachments} />
        </div>
                        }
      </AuthorisedArea>



      <Modal 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.uploadVisible} 
        onHide={() => this.handleCloseAttachment()} 
        animation={true}>
        
        <Modal.Header closeButton>
          <Modal.Title>Add Attachments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <input type="file" name="file" onChange={(e) => this.onChangeFileHandler(e)}/>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button  
            onClick={() => this.handleCloseAttachment()} 
            text="Cancel"
            variant="outline-secondary" 
            isBusy={this.state.isBusy}
            style={buttonSpacingStyle}
            icon={ButtonIcons.CANCEL}
          /> 
          <Button  
            onClick={() => this.handleUpload()} 
            text="Upload"
            variant="outline-primary" 
            isBusy={this.state.isBusy}
            style={buttonSpacingStyle}
            icon={ButtonIcons.OK}
          /> 
        </Modal.Footer>
      </Modal>



    </React.Fragment>
    )}
  }

  export default withRouter(withToastManager(TransactionEditor));