import React from 'react'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import {Row, Col} from 'react-bootstrap'
import SelectInput from './controls/selectInput'
import {Consumer} from '../context'
import LoaderDisplay from './controls/loaderDisplay'
import {AccountTypes, ButtonIcons} from '../Constants'
import DateInput from './controls/dateInput'
import CheckboxInput from './controls/checkboxInput'
import ReactGA from 'react-ga';
import CurrencyInput from "./controls/currencyInput";
import TextInput from "./controls/textInput";
import Moment from "react-moment";
import RadioInput from "./controls/radioInout";
import AccountsEditor from "./subcomponents/accountsEditor";
import Button from './controls/button'

class TransactionSearch extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dateTypesSelection: 0,
            amountTypeSelection: 0,
            accountFilter: false,

            fromDate: null,
            toDate: null,
            exactDate: null,
            datePeriod: 0,

            exactValueOption: false,
            valueRangeOption: false,

            includeDeleted: false,
            selectedCreditAccountId: null,
            selectedDebitAccountId: null,
            descriptionText: null,
            minValue: null,
            maxValue: null,
            exactValue: null,
            selectedCategoryId: null,
            selectedSubCategoryId: null,
            selectedBudgetId: null,
            accounts: [],
            isLoading: false,

            transactions: []
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
        this.context.getAllAccounts(false)
            .then((result) => {
                const accounts = result
                    .filter(item =>
                        (item.isActive === true && item.accountType === AccountTypes.THIRDPARTY) // Only active items
                    )
                    .sort(function (a, b) {
                        const textA = a.name.toUpperCase();
                        const textB = b.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    })
                    .map(item => ({id: item.id, value: item.name}))
                this.setState({
                    accounts
                })
            })
    }


    handleChange(fieldName, value) {
        this.setState({
            [fieldName]: value,
        })

    }

    handleSearch() {
        this.setState({
            isLoading: true
        })

        const searchRequest = {
            description: this.state.descriptionText,
            creditAccountId: this.state.selectedAccountId,
            maxValue: this.state.amountTypeSelection === 2 ? this.state.maxValue : null,
            minValue: this.state.amountTypeSelection === 2 ? this.state.minValue : null,
            exactValue: this.state.amountTypeSelection === 1 ? this.state.exactValue : null
        }
        this.context.transactionSearch(searchRequest)
            .then((data) => {
                this.setState({
                    transactions: data.data,
                    isLoading: false
                })
            })
    }


    render() {

        const periods = [
            {id: 0, value: "All"},
            {id: 1, value: "Past month"},
            {id: 2, value: "Past 2 months"},
            {id: 3, value: "Past 3 Months"},
            {id: 4, value: "Past 6 Months"},
            {id: 5, value: "Past 1 Year"},
        ]

        const dateTypes = [
            {label: "None", value: 0},
            {label: "Exact Date", value: 1},
            {label: "Date Range", value: 2},
            {label: "Date Period", value: 3},

        ]

        const amountTypes = [
            {label: "None", value: 0},
            {label: "Exact Amount", value: 1},
            {label: "Amount Range", value: 2},
        ]

        return (
            <AuthorisedArea showError>
                <div className="borderBoxStyle">
                    <Row>
                        <Col>
                            <h4>Transaction Search</h4>
                            <hr/>
                        </Col>
                    </Row>
                    <Row>


                        <Col md={"2"}>
                            <CheckboxInput label="Account Filter" value={this.state.accountFilter}

                                           onChange={(value) => this.handleChange('accountFilter', value)}/>
                        </Col>
                        {this.state.accountFilter &&
                            <Col md="8">
                                <AccountsEditor transactionTypeId={1}/>
                               
                               
                               
                            </Col>
                        }
                        <Col md="2">
                            <CheckboxInput label="Include Deleted" value={this.state.includeDeleted}
                                           onChange={(value) => this.handleChange('includeDeleted', value)}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={"5"}>
                            <RadioInput label="Date Selection" options={dateTypes} value={this.state.dateTypesSelection}
                                        onChange={(value) => this.handleChange("dateTypesSelection", value)}/>
                        </Col>

                        {this.state.dateTypesSelection === 1 &&
                            <Col md="3">
                                <DateInput label="Transaction Date" value={this.state.exactDate}
                                           onChange={(value) => this.handleChange('exactDate', value)}/>
                            </Col>
                        }
                        {this.state.dateTypesSelection === 2 &&
                            <React.Fragment>
                                <Col md="3">
                                    <DateInput label="From" value={this.state.fromDate}
                                               onChange={(value) => this.handleChange('fromDate', value)}/>
                                </Col>
                                <Col md="3">
                                    <DateInput label="To" value={this.state.toDate}
                                               onChange={(value) => this.handleChange('toDate', value)}/>
                                </Col>
                            </React.Fragment>
                        }
                        {this.state.dateTypesSelection === 3 &&
                            <Col md="3">
                                <SelectInput label="Report Period" value={this.state.datePeriod}
                                             onChange={(value) => this.handleChange('datePeriod', value)}
                                             options={periods}/>
                            </Col>
                        }
                    </Row>
                    <Row>
                        <Col md={"5"}>
                            <RadioInput label="Amount Selection" options={amountTypes}
                                        value={this.state.amountTypeSelection}
                                        onChange={(value) => this.handleChange("amountTypeSelection", value)}/>
                        </Col>

                        {this.state.amountTypeSelection === 1 &&
                            <Col md="3">
                                <CurrencyInput label={"Exact Amount"} value={this.state.exactValue}
                                               onChange={(value) => this.handleChange("exactValue", value)}/>
                            </Col>
                        }


                        {this.state.amountTypeSelection === 2 &&
                            <React.Fragment>
                                <Col md={"3"}>
                                    <CurrencyInput label={"Minimum Amount"} value={this.state.minValue}
                                                   onChange={(value) => this.handleChange("minValue", value)}/>
                                </Col>
                                <Col md={"3"}>
                                    <CurrencyInput label={"Maximum Amount"} value={this.state.maxValue}
                                                   onChange={(value) => this.handleChange("maxValue", value)}/>
                                </Col>
                            </React.Fragment>
                        }
                    </Row>
                    <Row>
                        <Col md={"4"}>
                            <TextInput
                                label={"Description"}
                                value={this.state.descriptionText}
                                onChange={(value) => this.handleChange("descriptionText", value)}
                                placeholder={"Description"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={"12"}>
                            <Button variant="primary" icon={ButtonIcons.SEARCH} text="Search" onClick={() => this.handleSearch()}>Search</Button>
                        </Col>
                    </Row>
                </div>
                <div className="borderBoxStyle top-buffer">
                    {this.state.isLoading &&
                        <LoaderDisplay/>
                    }
                    {!this.state.isLoading && this.state.transactions.length === 0 &&
                        <h2>None</h2>
                    }
                    {!this.state.isLoading && this.state.transactions.length > 0 &&
                        <React.Fragment>
                            <Row className="tableViewHeaderStyle">
                                <Col md="2">Transaction Date</Col>
                                <Col md="3">Description</Col>
                                <Col md="5">Transaction</Col>
                                <Col md="2" className="text-right">Transaction Value</Col>

                            </Row>
                            {this.state.transactions.map((item, index) =>
                                <Row className="tableViewRowStyle">
                                    <Col md={"2"}>
                                        <Moment format="Do MMM 'YY">{item.date}</Moment>
                                    </Col>
                                    <Col md={"3"}>
                                        {item.description}
                                    </Col>
                                    <Col md={"5"}>
                                        <strong>{item.transactionType}</strong>, {item.debitAccountName} to <strong>{item.creditAccountName}</strong>
                                    </Col>
                                    <Col md={"2"} className="text-right">
                                        <strong>{item.amount.toFixed(2)}</strong>
                                    </Col>
                                </Row>
                            )
                            }
                        </React.Fragment>
                    }
                </div>

            </AuthorisedArea>

        )
    }
}

TransactionSearch.contextType = Consumer
export default TransactionSearch