import React from 'react'
import { Row, Col } from 'react-bootstrap'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import LoaderDisplay from '../components/controls/loaderDisplay'
import { Consumer } from '../context'
import ReactGA from 'react-ga';
import Button from '../components/controls/button'
import { ButtonIcons } from '../Constants'
import LineChart from './charts/lineChart'
import { formatMoney } from '../Helpers/Formatter'
import SelectInput from './controls/selectInput'

class Investments extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            chartData: [],
            minValue: 0,
            maxValue: 0,
            selectedPeriod: 1
        }
    }

    populateChart() {

        this.context.getInvestmentChart(this.state.selectedPeriod)
        .then((result) => {
            const amounts = result.data.totalData.map(item => item.amount);
            const minValue = Math.min(...amounts);
            const maxValue = Math.max(...amounts);

            const datasets = [
                {
                  data: result.data.totalData.map((item) => { return { amount: item.amount.toFixed(2), label: item.label} })
                }
            ]
            this.setState({ 
                chartData: datasets,
                minValue,
                maxValue
            })


        });

    }

    populateGrid() {
        this.context.getInvestments(true) // Force an update as the data changes in the background
            .then(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
        this.populateChart();
        this.populateGrid();
    }

    View(investmentId) {
        this.props.history.push(`/investment/${investmentId}`);
    }

    Create() {
        this.props.history.push(`/investment/`);
    }

    onPeriodChange(value) {
        this.setState({
            selectedPeriod: value,
        }, () =>  {
            this.populateChart()
            this.populateGrid()
        })

    }
    

    render() {

        const periods = [
            {id: 1, value: "Past Hour"},
            {id: 2, value: "1 Day"},
            {id: 3, value: "1 Week"},
            {id: 4, value: "1 Month"},
        ]

        const chartHolder = {
            height: "15vh",
            width: "100%"
        }

        const buttonBarStyle = {
            display: "flex",
            justifyContent: "flex-end"
        }

        let startValue = 0
        let endValue = 0
        let valueChange = 0
        let percentageChange = 0
        let changeUp = 0 // -1 for down, 0 for same, 1 for up

        if(this.state.chartData && this.state.chartData.length > 0)
        {
            startValue = this.state.chartData[0].data[0].amount
            endValue = this.state.chartData[0].data[this.state.chartData[0].data.length -1].amount
            valueChange = endValue - startValue
            percentageChange = valueChange / startValue * 100
            changeUp = startValue < endValue ? 1 : startValue > endValue ? -1 : 0
        }

        return (
            <React.Fragment>
                <AuthorisedArea showError>
                    <div className="borderBoxStyle">
                        <Row>
                            <Col md="12">
                                <div style={buttonBarStyle}>
                                    <Button 
                                        onClick={() => this.Create()}
                                        text="Create New Investment"
                                        variant="primary"
                                        icon={ButtonIcons.CREATE}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="borderBoxStyle top-buffer">
                    <Row>
                            <Col md="12">Total Investment Value over time</Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <SelectInput label="Projection Period" options={periods} value={this.state.selectedPeriod} onChange={(value) => this.onPeriodChange(value)} help="The period to project" /> 
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <div style={chartHolder}>
                                    {this.state.chartData.length > 0 && <LineChart datasets={this.state.chartData} showGrid={true} minValue={this.state.minValue} maxValue={this.state.maxValue} />}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">Over this period, the investment total has {changeUp === 1 ? <span color='green'>increased</span> : <span color='red'>decreased</span>} by <strong>{formatMoney(valueChange)}</strong>, from {formatMoney(startValue)} to {formatMoney(endValue)}.  (That's a {percentageChange.toFixed(2)}% {changeUp === 1 ? <span >increase</span> : <span >decreased</span>})</Col>
                        </Row>
                    </div>

                    {this.state.isLoading && 
                        <LoaderDisplay />
                    }

                    {!this.state.isLoading && 
                        <div className="borderBoxStyle top-buffer">
                            <Row>
                                <Col md="12">
                                    <Row className="tableViewHeaderStyle">  
                                        <Col md="2">Investment</Col>
                                        <Col md="3" className="text-right">Volume Owned</Col>
                                        <Col md="2" className="text-right">Current Price </Col>
                                        <Col md="1" className="text-right">Past 1 hr</Col>
                                        <Col md="1" className="text-right">Past 24 hr</Col>
                                        <Col md="1" className="text-right">Past 7d</Col>
                                        <Col md="2" className="text-right">Total Value</Col>
                                    </Row>

                                    <Consumer.Consumer>
                                        {value => { 
                                            if(!value.cacheStore.investments)
                                                return(<div>No investments available</div>)

                                            // Calculate total value
                                            const totalValue = value.cacheStore.investments.reduce((acc, item) => acc + (item.investmentType.price * item.volumeOwned), 0);

                                            return (
                                                <>
                                                    {value.cacheStore.investments.map((item, index) => 
                                                        <Row 
                                                            key={index} 
                                                            onClick={() => this.View(item.id)}
                                                            className="tableViewRowStyle pointable" 
                                                        >
                                                            <Col md="2"><strong>{item.investmentType.name} <small>({item.investmentType.code})</small></strong></Col>
                                                            <Col md="3" className="text-right">{item.volumeOwned}</Col>
                                                            <Col md="2" className="text-right">
                                                                {formatMoney(item.investmentType.price)} 
                                                            </Col>

                                                            <Col md="1" className="text-right">
                                                                <strong>
                                                                    {item.investmentType.percentageChange1hour.toFixed(2)}% 
                                                                    <span style={{ color: item.investmentType.percentageChange1hour > 0 ? 'green' : 'red' }}>
                                                                        &nbsp;{item.investmentType.percentageChange1hour > 0 ? ButtonIcons.TRENDUP : ButtonIcons.TRENDDOWN}
                                                                    </span>
                                                                </strong>
                                                            </Col>
                                                            <Col md="1" className="text-right">
                                                                <strong>
                                                                    {item.investmentType.percentageChange24hours.toFixed(2)}%
                                                                    <span style={{ color: item.investmentType.percentageChange24hours > 0 ? 'green' : 'red' }}>
                                                                        &nbsp;{item.investmentType.percentageChange24hours > 0 ? ButtonIcons.TRENDUP : ButtonIcons.TRENDDOWN}
                                                                    </span>
                                                                </strong>
                                                            </Col>
                                                            <Col md="1" className="text-right">
                                                                <strong>
                                                                    {item.investmentType.percentageChange7days.toFixed(2)}%
                                                                    <span style={{ color: item.investmentType.percentageChange7days > 0 ? 'green' : 'red' }}>
                                                                        &nbsp;{item.investmentType.percentageChange7days > 0 ? ButtonIcons.TRENDUP : ButtonIcons.TRENDDOWN}
                                                                    </span>
                                                                </strong>
                                                            </Col>
                                                            <Col md="2" className="text-right"><strong>{formatMoney(item.investmentType.price * item.volumeOwned)}</strong></Col>
                                                        </Row>
                                                    )}

                                                    {/* Total Row */}
                                                    <Row className="tableViewRowStyle">
                                                        <Col md="12" className="text-right"><strong>{formatMoney(totalValue)}</strong></Col>
                                                    </Row>
                                                </>
                                            )
                                        }}
                                    </Consumer.Consumer>
                                </Col>
                            </Row>
                        </div>
                    }
                </AuthorisedArea>
            </React.Fragment>
        )
    }
}

Investments.contextType = Consumer
export default Investments
